import { template as template_b0484494ab1b48638be01fe8a4829745 } from "@ember/template-compiler";
import i18n from "discourse-common/helpers/i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_b0484494ab1b48638be01fe8a4829745(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
