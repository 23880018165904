import { template as template_dc7e97a724fc47b099f4f11b76e19a3a } from "@ember/template-compiler";
const WelcomeHeader = template_dc7e97a724fc47b099f4f11b76e19a3a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
