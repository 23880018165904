import { template as template_bce050ee96104d1fb5ada2c4e6d09d35 } from "@ember/template-compiler";
const FKText = template_bce050ee96104d1fb5ada2c4e6d09d35(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
