import { template as template_809fd608732d450a992fc900ad86cf36 } from "@ember/template-compiler";
const FKControlMenuContainer = template_809fd608732d450a992fc900ad86cf36(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
